@import "./scss/sorta-reset";

@font-face {
    font-family: "Reith";
    src: url("./fonts/BBCReithSans_W_Rg.woff2") format("woff2"), url("./fonts/BBCReithSans_W_Rg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Reith";
    src: url("./fonts/BBCReithSans_W_Md.woff") format("woff2"), url("./fonts/BBCReithSans_W_Md.woff2") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Reith";
    src: url("./fonts/BBCReithSans_W_Bd.woff") format("woff2"), url("./fonts/BBCReithSans_W_Bd.woff2") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "Reith", Helvetica, Arial, sans-serif;
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (hover: none) and (pointer: coarse) {
        overflow-y: scroll;
    }

    .app-root {
        overflow-x: hidden;
    }
}

body,
html,
.app-root {
    height: 100%;
}

p {
    a {
        color: white;
        text-decoration: underline;
    }
}

input,
textarea,
button {
    font-family: inherit;
}

.tabbing .app-root {
    a:focus,
    button:focus,
    input:focus,
    label:focus,
    select:focus,
    textarea:focus {
        outline: 2px solid red;
    }
}
